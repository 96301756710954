import Headings from '@narative/gatsby-theme-novela/src/components/Headings';
import Layout from '@narative/gatsby-theme-novela/src/components/Layout';
import Paragraph from '@narative/gatsby-theme-novela/src/components/Paragraph';
import Section from '@narative/gatsby-theme-novela/src/components/Section';
import SEO from '@narative/gatsby-theme-novela/src/components/SEO';
import React from 'react';

function NotFoundPage() {
  return (
    <Layout>
      <SEO />
      <Section style={{ height: '1000px' }}>
        <Headings.h1 style={{ paddingTop: '5rem' }}>
          These are not the droids you're looking for...
        </Headings.h1>
        <Paragraph style={{ paddingTop: '3rem' }}>
          <img
            src="https://media.giphy.com/media/4560Nv2656Gv0Lvp9F/giphy.gif"
            alt="Obi-Wan"
            height="100%"
            width="100%"
          ></img>
        </Paragraph>
        <Headings.h2 style={{ paddingTop: '2rem' }}>
          <a href="/" style={{ color: '#6B7A99' }}>
            Move along...
          </a>
        </Headings.h2>
      </Section>
    </Layout>
  );
}

export default NotFoundPage;
